/* global globalThis */
import {
  ADS_PAUSED,
  AD_TARGETING_UPDATED,
  CHAPTERS_LOADED,
  PLAYER_STATE_UPDATED,
  PROPERTY_UPDATED,
  SEEKED_TO,
  PLAYER_LOADED,
  ADPLOGGER_SDK_INIT,
  ADPLOGGER_SDK_LOADED,
  UNMUTE,
  OVP_CONFIG_LOADED,
  AD_ERROR,
  PLAYING_STATE_UPDATED,
} from './actions.js';

const DEFAULT_PLAYER_ID = 'c7fa8467-544d-45a6-818f-c28fa19be069';

export const initialState = () => {
  const urlParams = new URLSearchParams(globalThis.location?.search);
  const enableSsai = !!(
    urlParams.get('enable_ssai') ||
    globalThis.location?.origin?.includes('salsaposten')
  );

  return {
    id: `brick-player:${new Date().toISOString()}`,
    isDebugLogging: !!urlParams.get('debug') === 'true',
    containerWidth: 0,
    features: {
      enableSsai,
    },
    pageUrl: globalThis.location.href,
    shouldShowUnmute: false,
    prefersReducedMotion: globalThis.matchMedia(
      '(prefers-reduced-motion: reduce)'
    ).matches,
    playerState: {},
    playingState: undefined,
    ovpConfig: {},
    ad: {},
    playerId: DEFAULT_PLAYER_ID,
  };
};

export function rootReducer(state = initialState(), action) {
  // thunks
  if (!action) {
    return state;
  }
  switch (action.type) {
    case ADS_PAUSED:
      return { ...state, adsIsPaused: action.payload };
    case AD_ERROR:
      return { ...state, adError: action.payload };
    case AD_TARGETING_UPDATED:
      return { ...state, ad: action.payload };
    case CHAPTERS_LOADED:
      return { ...state, chapters: action.payload };
    case PLAYER_STATE_UPDATED:
      return {
        ...state,
        playerState: {
          ...state.playerState,
          isRendered: !!action.payload.isRendered,
          isLive: !!action.payload['is-live'],
          isStarting: !!action.payload['is-starting'],
          isInViewport: !!action.payload['is-in-viewport'],
          isLoaded: !!action.payload['is-loaded'],
          isMuted: !!action.payload['is-muted'],
          isAdPlaying:
            !!action.payload['ad-linear'] || !!action.payload['ad-non-linear'],
        },
      };
    case PLAYING_STATE_UPDATED:
      return {
        ...state,
        playingState: action.payload,
      };
    case PROPERTY_UPDATED:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case SEEKED_TO:
      return { ...state, seek: action.payload };
    case PLAYER_LOADED:
      return {
        ...state,
        player: action.payload,
      };
    case ADPLOGGER_SDK_LOADED:
      return {
        ...state,
        adpLogger: action.payload,
      };
    case UNMUTE:
      return {
        ...state,
        unmuteHidden: true,
      };
    case OVP_CONFIG_LOADED:
      return {
        ...state,
        ovpConfig: action.payload,
      };
    case ADPLOGGER_SDK_INIT:
      return {
        ...state,
        adpLoggerInstance: action.payload.adpLoggerInstance,
      };
    default:
      return state;
  }
}
